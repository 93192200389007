import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { NuevoUsuarioComponent } from '../../modal/nuevo-usuario/nuevo-usuario.component';
import { RecuperarPassComponent } from '../../modal/recuperar-pass/recuperar-pass.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginUser = {
    user: '',
    pass: ''
  };

  imagen = '../../assets/img/accesoazul.png';
  titulo = 'Acceso usuario';

  constructor(private loginService: LoginService,
              private popOverCtrl: PopoverController,
              private modalCtrl: ModalController) { }


  ngOnInit() { }

  async login() {

      const resp: any = await this.loginService.loginCliente(this.loginUser.user, this.loginUser.pass);
      if (resp) {
        this.popOverCtrl.dismiss(resp);
      }
  }

  async nuevoUsuario() {
  const modal = await this.modalCtrl.create({
        component: NuevoUsuarioComponent,
        cssClass: 'nuevaEmpresa-modal',
        backdropDismiss: false,
       });

  await modal.present();
  this.popOverCtrl.dismiss();
  const { data } = await modal.onWillDismiss();
  }

  async reuperarPasss(){
    const modal = await this.modalCtrl.create({
      component: RecuperarPassComponent,
      cssClass: 'recuperar-modal',
      backdropDismiss: false,
    });

    await modal.present();
    this.popOverCtrl.dismiss();
    const { data } = await modal.onWillDismiss();
    if (data){
      console.log(data);
    }
  }
}
