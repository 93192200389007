import { Injectable } from '@angular/core';
import { Angular2PrestaService, Angular2PrestaQuery } from 'angular2-presta';
import { UsuarioPS } from '../interfaces/ps.interface';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Cliente, ClienteResp } from '../interfaces/clientes.interface';
import { environment } from 'src/environments/environment';
import { GenaralDataService } from './general-data.service';


const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class PrestashopService {

  constructor(private a2pservice: Angular2PrestaService,
    private http: HttpClient,
    private genaralDataService: GenaralDataService,
    private storage: Storage) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  headers = {
    Authorization: 'Basic ' + environment.shopConfig.apiKey,
    'Output-Format': 'JSON',
    'Content-Type': 'application/xml'
  };


  getUsuarios() {
    const query: Angular2PrestaQuery = {
      resource: 'customers',
    };
    return new Promise<UsuarioPS[]>(resolve => {
      this.a2pservice.get(query).subscribe((data: UsuarioPS[]) => {
        resolve(data);
      });
    });
  }

  async cargarClientes() {
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes`, { headers: this.header }).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  existeUsuario(email: string) {
    const query: Angular2PrestaQuery = {
      resource: 'customers',
      filter: {'email': email}
    };
    return new Promise<boolean>(resolve => {
      this.a2pservice.get(query).subscribe((data: UsuarioPS[]) => {
      //   data.forEach(d => {
      //     if (String(d.email) === email) {
      //       resolve(true);
      //     }
      //   });
      //   resolve(false);
      // });
      if(!data){
        console.log('NO');
        resolve(false);
      } else {
        console.log('SI');
        resolve(true);
      }
    });
  });
}

  getAddress(id_customer: number) {
    const query: Angular2PrestaQuery = {
      resource: `addresses`,
    };
    return new Promise<any[]>(resolve => {
      this.a2pservice.get(query).subscribe((data: any[]) => {
        const addresses: any[] = [];
        data.forEach(f => {
          if (Number(f.id_customer) === Number(id_customer)) {
            addresses.push(f);
          }
        });
        resolve(addresses);
      });
    });
  }

  async nuevoCliente(cliente: Cliente) {
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.post(`${URL}/clientes-ps/`, { cliente }, { headers: this.header }).subscribe(async (resp: ClienteResp) => {
        console.log(resp);
        resolve(resp);
      });
    });
  }

  async sincUsuarios() {
    return new Promise<any>(async resolve => {
      const appUser = await this.cargarClientes();
      if (!appUser.ok) {
        resolve({
          ok: false,
          error: 'No se han obtenido datos desde la base de datos, contacte con soporte técnico'
        });
      }

      const userPs = await this.getUsuarios();
      if (!userPs) {
        resolve({
          ok: false,
          error: 'No se han obtenido datos desde la tienda, contacte con soporte técnico'
        });
      }
      if (userPs.length < appUser.clientes.length) {
        resolve({
          ok: false,
          error: 'No esposible sincronizar desde la tienda, posible fallo de integridad de datos'
        });
      }
      if (userPs.length === appUser.clientes.length) {
        resolve({
          ok: true,
          sinc: 0
        });
      }
      const newUser: any[] = [];
      let n= 0;
      userPs.forEach(ps => {
        let existe = false;
        appUser.clientes.forEach(c => {
          if (c.email === ps.email) {
            existe = true;
          }
        });
        if(!existe){
          newUser.push(ps);
        }
      });

      let valido = 0;
      newUser.forEach(async nu => {
        const address = await this.getAddress(nu.id);
        if (address.length > 0) {     

            let idState: any;
            if (address[0].id_state) {
              const resp: any = await this.genaralDataService.getProvincia(Number(address[0].id_state));
              if (resp.ok) {
                idState = resp.provincia._id;
              }
            }
            const cliente: Cliente = {
              idPs: nu.id,
              nombre: nu.firstname,
              apellido1: nu.lastname,
              email: nu.email,
              nif: address[0].dni,
              localidad: address[0].city,
              provincia: idState || undefined,
              telefono: address[0].phone,
              direccion: address[0].address,
              cp: address[0].postcode
            };
            const save = await this.nuevoCliente(cliente);
            if (save.ok) {
              valido++;             
            } 
        }
      });
      resolve({
        ok: true,
        sinc: valido
      });
    });
  }


}
