import { ArticulosService } from './../services/articulos.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'packArticulo'
})
export class PackArticuloPipe implements PipeTransform {

  constructor(private artSercie: ArticulosService){}

  async transform(artId: string) {
    const resp = await this.artSercie.getArticulo(artId);
    if (resp.ok){
      return resp.articulo.nombre;
  }
}

}
