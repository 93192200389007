import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { NgxBarcodeModule } from 'ngx-barcode';



// importar locales
import localePy from '@angular/common/locales/es-PY';
import { registerLocaleData } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';
import { LoginComponent } from './components/login/login.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Stripe } from '@ionic-native/stripe/ngx';
import { ModalModule } from './modal/modal.module';
import { Angular2PrestaConfiguration, Angular2PrestaModule } from 'angular2-presta';

const a2pConfig: Angular2PrestaConfiguration = {
    apiKey: 'ETH7498PIGDNP2VLNDD51LTC8948RZPW',
    shopUrl: 'https://www.sanafarmacia.com/api/',
};

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');

@NgModule({
  declarations: [AppComponent, LoginComponent ],
  entryComponents: [],
  imports: [
            BrowserModule,
            NgxBarcodeModule,
            IonicModule.forRoot(),
            AppRoutingModule,
            FormsModule,
            HttpClientModule,
            ChartsModule,
            Angular2PrestaModule.forRoot(a2pConfig),
            QuillModule.forRoot(),
            IonicStorageModule.forRoot(),
            PipesModule,
            ModalModule,
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
          ],
  providers: [
    StatusBar,
    SplashScreen,
    Stripe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, {provide: LOCALE_ID, useValue: 'es'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
