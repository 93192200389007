import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { ArticuloResp } from '../interfaces/articulos.inteface';
import { PacksResp } from '../interfaces/packs.inteface';

const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  async getArticulos(){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.get(`${URL}/articulos-tienda`, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async getArticulo(artId: string){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.get(`${URL}/articulos/${artId}`, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async getPack(){
    await this.cargarHeader();
    return new Promise<PacksResp>(resolve => {
      this.http.get(`${URL}/packs`, {headers: this.header}).subscribe((resp: PacksResp) => {
        resolve(resp);
      });
    });
  }
}
