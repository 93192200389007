
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AvisoLegalComponent } from '../aviso-legal/aviso-legal.component';
import { UsuarioService } from '../../services/usuario.service';
import { Cliente } from '../../interfaces/clientes.interface';
import { UiService } from '../../services/ui.service';
import { GenaralDataService } from '../../services/general-data.service';
import { PrestashopService } from '../../services/prestashop.service';
import { Empresa } from '../../interfaces/empresa.interface';
import { EmpresasService } from '../../services/empresas.service';


@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.scss'],
})
export class NuevoUsuarioComponent implements OnInit {

  @Input()usuario: Cliente = {};
  @Input()soyEmpresa: boolean; 
  empresa: Empresa = {};
  size = 6;
  provincias: any[] = [];
  constructor(  private modalCtrl: ModalController,
                private usuarioService: UsuarioService,
                private ui: UiService,
                private empresaService: EmpresasService,
                private generalDataService: GenaralDataService,
                private psService: PrestashopService) { }

  async ngOnInit() {
    if (this.usuario._id){
      this.size = 4;
    }
    const state: any = await this.generalDataService.getProvincias();
     if (state.ok){
      this.provincias = state.provincias;
    }
  }

  cambioPE(){
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

  async formAutonomo(){
    const existePs = await this.psService.existeUsuario(this.usuario.email);
        if (existePs){
          this.ui.alertError('El cliente ya esta dado de alta en la tienda. Póngase en contacto con la farmacia. Gracias');
          return;
        }
    let resp;
    if (this.soyEmpresa){
      resp = await this.usuarioService.nuevoUsuario(this.usuario, this.empresa);    
    } else {
      resp = await this.usuarioService.nuevoUsuario(this.usuario);    
    }
    if (resp.ok){
      this.modalCtrl.dismiss('ok');
    } else {
      this.ui.alertError(resp.error);
    }
  }

  async legal(){
    const modal = await this.modalCtrl.create({
      component: AvisoLegalComponent,
      cssClass: 'legal-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.usuario.lopd = data.lopd;
  }

  async reenviarValidador(){
    const resp: any = await this.empresaService.reenvioValidador(this.empresa.cif);
    if (!resp.ok){
      this.ui.alertError(resp.error);
      return;
    }
    this.ui.presentToast('Validador reenviado');
  }

}
