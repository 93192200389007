import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UsuarioService } from '../../services/usuario.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.scss'],
})
export class RecuperarPassComponent implements OnInit {

  constructor( private modalCtrl: ModalController, private usuarioService: UsuarioService, private ui: UiService) { }

  email = '';

  ngOnInit() {}

  cerrar(){
    this.modalCtrl.dismiss();
  }

  async formpass(){
    const resp = await this.usuarioService.recuperaPass(this.email);
    if (resp.ok){
      this.ui.presentToast('Hemos enviado un correo con la nueva contraseña.');
    }
    this.modalCtrl.dismiss();
  }

}
