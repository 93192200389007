import { Direccion } from './../interfaces/direccion.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Usuario } from '../interfaces/usuario.interface';
import { ClienteResp, Cliente } from '../interfaces/clientes.interface';
import { Empresa } from '../interfaces/empresa.interface';
import { PrestashopService } from './prestashop.service';
import { DireccionResp } from '../interfaces/direccion.interface';


const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private storage: Storage, private http: HttpClient, private psService: PrestashopService) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  async nuevoUsuario(usuario: Usuario, empresa?: Empresa){

    return new Promise<ClienteResp>(resolve => {

        if (empresa){
          this.http.post(`${URL}/clientes`, {usuario, empresa}).subscribe(async (resp: ClienteResp) => {
            resolve(resp);
          });
        } else {
          this.http.post(`${URL}/clientes`, {usuario}).subscribe(async (resp: ClienteResp) => {
            resolve(resp);
          });
        }
    });
  }

  async getUsuario(id: string){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes/${id}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }
  async updateUsuario(usuario: Cliente){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.put(`${URL}/clientes/${usuario._id}`, usuario, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  recuperaPass(email: string){
    return new Promise<ClienteResp>(resolve => {
      this.http.put(`${URL}/clientes/recupera/pass`, {email}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async getDirecciones() {
    await this.cargarHeader();
    return new Promise<DireccionResp>(resolve => {
      this.http.get(`${URL}/cliente-direccion`, {headers: this.header}).subscribe((resp: DireccionResp) => {
        resolve(resp);
      });
    });
  }

  async setDireccion(direccion: Direccion){
    await this.cargarHeader();
    return new Promise<DireccionResp>(resolve => {
      this.http.post(`${URL}/cliente-direccion`, direccion, {headers: this.header}).subscribe((resp: DireccionResp) => {
        resolve(resp);
      });
    });
  }
}
