import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer'
})
export class DomSanitizerPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer){}

  transform(img: string, type: string): any {

    switch (type){
      case 'img':
        const domImg = `background-image: url('${ img }')`;
        return this.domSanitizer.bypassSecurityTrustStyle( domImg );
      case 'url':
        return this.domSanitizer.bypassSecurityTrustUrl( img );
    }
  }

}
