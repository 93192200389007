import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { Empresa, EmpresaResp } from '../interfaces/empresa.interface';

const URL = environment.backendUrl;
@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  async setEmpresa(empresa: Empresa){
    console.log(empresa);
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.post(`${URL}/empresas`, empresa).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async cargarEmpresa(empId: string) {
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.get(`${URL}/empresas/${empId}`, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async reenvioValidador(cif: string){
    return new Promise(resolve => {
      this.http.post(`${URL}/empresas/validador`, {cif}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
}
