import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.scss'],
})
export class AvisoLegalComponent implements OnInit {


  lopd = false;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  onClickAceptar() {
    this.modalController.dismiss({
      lopd: true
    });
  }

  onClickRechazar() {
    this.modalController.dismiss({
      lopd: false
    });
  }

}
