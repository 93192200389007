import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { environment } from '../environments/environment';

const appRouteAndorid = 'https://app.sanafarmacia.com';
const appRouteIos = 'https://app.sanafarmacia.com';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      const ua = navigator.userAgent;
      if (ua.includes('iPhone')) {
        console.log('iPhone');
        window.location.href = appRouteIos;
        return;
      }
      if (ua.includes('Android')) {
        console.log(`Android`);
        window.location.href = appRouteAndorid;
        return;
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
