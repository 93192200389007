import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private alertCrtl: AlertController,
              private toastController: ToastController) { }

  async alertError(mensaje: string) {
    const alert = await this.alertCrtl.create({
      header: 'Error',
      message: mensaje,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

}
