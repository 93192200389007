import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment.prod';

const URL = environment.backendUrl;

@Pipe({
  name: 'imagenArticulo'
})
export class ImagenArticuloPipe implements PipeTransform {

  transform(img: string): string {
    return `${URL}/download/articulo/${img}`;
  }

}
