import { Pipe, PipeTransform } from '@angular/core';
import { Pack } from '../interfaces/packs.inteface';

@Pipe({
  name: 'paks'
})
export class PaksPipe implements PipeTransform {

  transform(paks: Pack[], buscar: string): Pack[] {
    if (!buscar){
      return paks;
    }
    return paks.filter(f => {
        return f.nombre.toLowerCase().includes(buscar.toLowerCase()) || f.descripcion.toLowerCase().includes(buscar.toLowerCase());
    });
  }

}
