import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { UsuarioPresp } from '../interfaces/usuario.interface';

const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( private storage: Storage,
               private http: HttpClient
             ) {}

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async loginCliente(email: string, pass: string){
    await this.cargarHeader();
    return new Promise(async (resolve) => {
      if (this.header.token){
        this.http.get(`${URL}/usuarios/validar`, {headers: this.header}).subscribe((resp: UsuarioPresp) => {
          resolve(resp);
            });
      }
      this.http.post(`${URL}/clientes/login`, {email, password: pass}, {}).subscribe( async (login: any) => {
        if (!login.ok){
          resolve(login);
        }
        await this.storage.set('token', login.token);
        this.header.token = login.token;
        this.http.get(`${URL}/usuarios/validar`, {headers: this.header}).subscribe((resp: UsuarioPresp) => {
        resolve(resp);
          });
      });
    });
  }

  loginEmpresa(email: string, pass: string){
    return new Promise(async (resolve) => {
      await this.cargarHeader();
      if (this.header.token){
        resolve(this.verificaLogin);
      }
      this.http.post(`${URL}/empresas/login`, {email, password: pass}, {}).subscribe( async (login: any) => {
        if (!login.ok){
          resolve(false);
        }
        await this.storage.set('token', login.token);
        this.header.token = login.token;
        resolve(await this.verificaLogin());
      });
    });
  }

  async verificaLogin(){
    await this.cargarHeader();
    return new Promise(resolve => {
        this.http.get(`${URL}/usuarios/validar`, {headers: this.header}).subscribe((resp: any) => {
          resolve(resp);
        });
    });
  }

  async logout(){
    this.header.token = '';
    await this.storage.clear();
    return true;
  }
}
