import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenArticuloPipe } from './imagen-articulo.pipe';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { ArticulosPipe } from './articulos.pipe';
import { PaksPipe } from './paks.pipe';
import { PackArticuloPipe } from './pack-articulo.pipe';



@NgModule({
  declarations: [ImagenArticuloPipe, DomSanitizerPipe, ArticulosPipe, PaksPipe, PackArticuloPipe, PackArticuloPipe],
  imports: [
    CommonModule
  ],
  exports: [ImagenArticuloPipe, DomSanitizerPipe, ArticulosPipe, PaksPipe, PackArticuloPipe]
})
export class PipesModule { }
