import { Pipe, PipeTransform } from '@angular/core';
import { Articulo } from '../interfaces/articulos.inteface';

@Pipe({
  name: 'articulos'
})
export class ArticulosPipe implements PipeTransform {

  transform(arts: Articulo[], buscar: string): Articulo[] {
    if (!buscar){
      return arts;
    }
    return arts.filter(f => {
        return f.nombre.toLowerCase().includes(buscar.toLowerCase()) || f.descripcionCorta.toLowerCase().includes(buscar.toLowerCase());
    });
  }

}
