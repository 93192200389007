export const environment = {
  production: true,
  //backendUrl: 'http://localhost:3001',
  backendUrl: 'https://backendserver.grupoinova.es:3001',
  urlWeb: 'https://sanafarmacia.com',
  appRouteAndroid: 'https://play.google.com/store/apps/details?id=es.inovacloud.sanafarmaciace',
  appRputeIos: 'https://play.google.com/store/apps/details?id=es.inovacloud.sanafarmaciace',
  shopConfig: {
    apiKey: 'ETH7498PIGDNP2VLNDD51LTC8948RZPW',
    shopUrl: ' https://sanafarmacia.com/api/',
  },
};
