import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { NuevoUsuarioComponent } from './nuevo-usuario/nuevo-usuario.component';
import { FormsModule } from '@angular/forms';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';
import { ChartsModule } from 'ng2-charts';
import { PipesModule } from '../pipes/pipes.module';


const modal = [
  AvisoLegalComponent,
  NuevoUsuarioComponent,
  RecuperarPassComponent,

];

@NgModule({
  declarations: modal,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ChartsModule,
    PipesModule,
  ]
})
export class ModalModule { }
